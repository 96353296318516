// @flow

import React from "react";
import App from "./App";
import { CookiesProvider } from "react-cookie";
import styled from "styled-components";

export default function Root() {
  return (
    <WideScreenStyle>
      <PageWrapper>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </PageWrapper>
    </WideScreenStyle>
  );
}

const WideScreenStyle = styled.div`
  background: #1f1f1f;
`;

const PageWrapper = styled.div`
  max-width: 1920px;
  margin: auto;
  
  p {
    font-family: "Titillium Web", sans-serif;
    letter-spacing: 1px;
  }
`;
