export const sliderImages = [
  {
    image:
      "https://cosmic-s3.imgix.net/129370b0-db96-11e8-874e-2decf9906882-RoadRedemption1.png",
    title:
      '"I f****** loved this game. It is the most fun I have had doing anything in a while"',
    description: "9.0 - Destructoid"
  },

  {
    image:
      "https://cosmic-s3.imgix.net/195a8460-db96-11e8-b46e-07c3195bfa25-RoadRedemption3.png",
    title:
      '"It looks nice, has a rocking soundtrack and a surprising amount of depth"',
    description: "90% - GameSpew"
  },

  {
    image:
      "https://cosmic-s3.imgix.net/1eca4570-db96-11e8-b46e-07c3195bfa25-RoadRedemption4.png",
    title: '"An incredibly successful spiritual successor to Road Rash"',
    description: "90/100 - Ragequit"
  }

  // {
  //   image:
  //     "https://cosmic-s3.imgix.net/2551b810-db96-11e8-874e-2decf9906882-RoadRedemption7.png"
  // },

  // {
  //   image:
  //     "https://cosmic-s3.imgix.net/2a7d1d20-db96-11e8-874e-2decf9906882-RoadRedemption8.png"
  // },

  // {
  //   image:
  //     "https://cosmic-s3.imgix.net/30a7a2b0-db96-11e8-b46e-07c3195bfa25-RoadRedemption11.png"
  // }
];
