import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

export default class SocialLinks extends Component {
  render() {
    return (
      <SocialWrapper>
        <a
          href="https://www.facebook.com/RoadRedemptionGame/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="facebook" size="big" className="social-icon" />
        </a>
        <a
          href="https://youtu.be/3P06rdib6EU"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="youtube" size="big" className="social-icon" />
        </a>
        <a
          href="https://twitter.com/roadredemption"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="twitter" size="big" className="social-icon" />
        </a>
        <a
          href="https://www.instagram.com/tripwireinteractive/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="instagram" size="big" className="social-icon" />
        </a>
        <a
          href="https://www.twitch.tv/tripwireinteractive"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="twitch" size="big" className="social-icon" />
        </a>
      </SocialWrapper>
    );
  }
}

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px 20vw;
  margin-bottom: 40px;
  background: #4b4b4b;
  background: -webkit-radial-gradient(center, #4b4b4b, #1f1f1f);
  background: -moz-radial-gradient(center, #4b4b4b, #1f1f1f);
  background: radial-gradient(ellipse at center, #4b4b4b, #1f1f1f);
  border-top: solid;
  border-width: 2px;
  border-color: #fde704;

  a {
    margin: 2vw;
    color: white;
  }

  a:hover {
    color: #fde704;
  }

  @media (max-width: 1608px) {
    padding: 20px 15vw;
  }

  @media (max-width: 1378px) {
    padding: 20px 10vw;
  }

  @media (max-width: 650px) {
    padding: 20px 5vw;
  }
`;
