import React, { Component } from "react";
import axios from "axios";
import Media from "react-media";
import ReactGA from "react-ga";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { Button, Modal, Form, Input, Message } from "semantic-ui-react";
import styled from "styled-components";

import MobileBanner from "./components/MobileBanner";
import Carousel from "./components/Carousel";
import VideoLoop from "./components/VideoLoop";
import BuyNowLinks from "./components/BuyNowLinks";
import SocialLinks from "./components/SocialLinks";
import Trailer from "./components/Trailer";
import Footer from "./components/Footer";

ReactGA.initialize("UA-384258-16");
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;

    this.state = {
      day: "",
      month: "",
      year: "",
      age: cookies.get("age") || 0,
      message: "Please enter a valid Date of Birth",
      open: true,
      closeOnEscape: false,
      closeOnDimmerClick: false,
      currentSteamPrice: "",
      currentSteamDiscount: 0
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit() {
    if (this.state.day < 1 || this.state.day > 31) {
      this.setState({ message: "Please enter a valid day" });
    } else if (this.state.month < 1 || this.state.month > 12) {
      this.setState({ message: "Please enter a valid month" });
    } else if (this.state.year < 1900 || this.state.year > 2019) {
      this.setState({ message: "Please enter a valid year" });
    } else {
      const dob = this.state.year + this.state.month + this.state.day;
      const year = Number(dob.substr(0, 4));
      const month = Number(dob.substr(4, 2)) - 1;
      const day = Number(dob.substr(6, 2));
      const today = new Date();
      let age = today.getFullYear() - year;

      if (
        today.getMonth() < month ||
        (today.getMonth() === month && today.getDate() < day)
      ) {
        age--;
      }

      const { cookies } = this.props;
      cookies.set("age", age, { path: "/" });
      this.setState({ age });
      window.location.reload();
    }
  }

  closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
    this.setState({ closeOnEscape, closeOnDimmerClick, open: true });
  };

  close = () => this.setState({ open: false });

  componentDidMount() {
    this.getCurrentSteamPrice();
  }

  getCurrentSteamPrice = async () => {
    try {
      const res = await axios.get(
        "https://cors-anywhere.herokuapp.com/https://store.steampowered.com/api/appdetails?appids=300380&cc=us&filters=price_overview"
      );
      const currentSteamPrice =
        res.data["300380"].data.price_overview.final_formatted;
      const currentSteamDiscount =
        res.data["300380"].data.price_overview.discount_percent;
      this.setState({ currentSteamPrice, currentSteamDiscount });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { open, closeOnEscape, closeOnDimmerClick } = this.state;
    const validAge = this.state.age !== 0 && this.state.age >= 17;
    const modal =
      this.state.age === 0 ? (
        <div>
          <Modal
            basic
            size="large"
            style={{
              textAlign: "center",
              background: "rgba(0, 0, 0, 0.5)",
              borderRadius: "5px"
            }}
            open={open}
            closeOnEscape={closeOnEscape}
            closeOnDimmerClick={closeOnDimmerClick}
            onClose={this.close}
          >
            <Modal.Header style={{ border: "none" }}>
              Warning: Restricted Content
            </Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Group widths="equal">
                  <Form.Field required>
                    <label style={{ color: "white" }}>Day</label>
                    <Input
                      placeholder="DD"
                      name="day"
                      type="number"
                      size="large"
                      value={this.state.day}
                      onChange={this.handleInputChange}
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label style={{ color: "white" }}>Month</label>
                    <Input
                      placeholder="MM"
                      name="month"
                      type="number"
                      size="large"
                      value={this.state.month}
                      onChange={this.handleInputChange}
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label style={{ color: "white" }}>Year</label>
                    <Input
                      placeholder="YYYY"
                      name="year"
                      type="number"
                      size="large"
                      value={this.state.year}
                      onChange={this.handleInputChange}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              <Message
                style={{
                  color: "white",
                  textAlign: "center",
                  background: "rgba(0, 0, 0, 0)"
                }}
              >
                <p>{this.state.message}</p>
              </Message>
            </Modal.Content>
            <Modal.Actions
              style={{
                display: "flex",
                justifyContent: "center",
                border: "none"
              }}
            >
              <Button
                onClick={this.handleSubmit}
                positive
                labelPosition="right"
                icon="checkmark"
                content="Check Age"
              />
            </Modal.Actions>
          </Modal>
          <ModalWrapper />
        </div>
      ) : (
        <div>
          <Modal
            basic
            size="large"
            style={{
              textAlign: "center",
              background: "rgba(0, 0, 0, 0.5)",
              borderRadius: "5px"
            }}
            open={open}
            closeOnEscape={closeOnEscape}
            closeOnDimmerClick={closeOnDimmerClick}
            onClose={this.close}
          >
            <Modal.Header style={{ border: "none" }}>
              Restricted Content
            </Modal.Header>
            <Modal.Content>
              <Message
                style={{
                  color: "white",
                  textAlign: "center",
                  background: "rgba(0, 0, 0, 0)"
                }}
              >
                <p>Sorry, you do not have access to this content</p>
              </Message>
            </Modal.Content>
          </Modal>
          <ModalWrapper />
        </div>
      );
    return (
      <div>
        {validAge ? (
          <React.Fragment>
            <Media query="(max-width: 1024px)">
              {matches => (matches ? <MobileBanner /> : <VideoLoop />)}
            </Media>
            <BuyNowLinks
              currentSteamPrice={this.state.currentSteamPrice}
              currentSteamDiscount={this.state.currentSteamDiscount}
            />
            <SocialLinks />
            <TextWrapper>
              <h2>Road Redemption - Now Available on Consoles and PC!</h2>
              <p>
                Lead a biker gang on an epic journey across the country in Road
                Redemption, a driving combat road rage adventure. Set in a
                post-apocalyptic world ruled by warring biker gangs, Road
                Redemption finds players racing against rivals through enemy
                territory in pursuit of a $15,000,000 bounty reward for the head
                of the assassin behind the death of the country’s wealthiest
                weapons cartel. It’s up to you and fellow gang members to chase
                down the elusive assassin and claim the prize against all odds.
              </p>
            </TextWrapper>
            <Trailer />
            <TextWrapper>
              <p>
                Blazing past hairpin turns and racing over rooftops while
                swinging a chained bat is dangerous, and death can come quickly
                in Road Redemption. Fortunately, every time you die, all the
                experience earned can be used to upgrade your character, bike,
                and weapons. Earn money and collect loot by completing races,
                assassinations, robberies, and other challenges to build the
                ultimate road warrior. Complete with a huge single-player
                campaign, dozens of weapons, up to 4-player co-op split-screen,
                and online multiplayer, Road Redemption pulls no stops in
                delivering the ultimate combat racing experience.
              </p>
              <p>
                For more information visit the{" "}
                <a href="http://www.roadredemption.com/">
                  official Road Redemption website
                </a>
                .
              </p>
            </TextWrapper>
            <TextWrapper>
              <h2>
                Read what PC gamers are already saying about Road Redemption...
              </h2>
            </TextWrapper>
            <Carousel />
            <TextWrapper>
              <h2>A Killer Crossover</h2>
              <p>
                Road Redemption players on Steam can now experience an exciting
                crossover with Killing Floor 2! Players who own both games will
                receive access to two new playable characters in Road Redemption
                from Killing Floor 2: Hans Volter and DJ Skully...
              </p>
            </TextWrapper>
            <ImageWrapper>
              <img
                src="https://cosmic-s3.imgix.net/8f8bc9f0-dba5-11e8-9351-bf75ddd1f5d4-KF2_RR_CrossoverBannerV2.png"
                alt="KF2/Road Redemption Crossiver"
              />
            </ImageWrapper>
            <TextWrapper>
              <p>
                Killing Floor 2 players will gain access to the “Road Redeemer”,
                a chained bat and favored melee weapon of road warriors in Road
                Redemption. The Road Redeemer is a Tier 2 melee weapon that can
                be found under the Berserker Perk. Console players can also take
                advantage of this killer update!
              </p>
            </TextWrapper>
            <TextWrapper style={{ paddingBottom: "20px" }}>
              <p>
                Road Redemption is a property of Pixel Dash Studios and
                EQ-Games.
              </p>
            </TextWrapper>
            <Footer />
          </React.Fragment>
        ) : (
          modal
        )}
      </div>
    );
  }
}

export default withCookies(App);

const ModalWrapper = styled.div`
  height: 100vh;
  background-image: url("https://cosmic-s3.imgix.net/195a8460-db96-11e8-b46e-07c3195bfa25-RoadRedemption3.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

const TextWrapper = styled.div`
  margin: 40px 0;
  padding: 0 20vw;

  h2 {
    font-size: 24px;
    letter-spacing: 3px;
    text-align: center;
    color: #fde704;
    text-shadow: 1px 1px #000000;
    padding: 10px 0;
  }

  a {
    color: #fde704;
    text-decoration: none !important;
  }

  a:hover {
    text-decoration: underline !important;
  }

  p,
  li {
    text-shadow: 1px 1px black;
    font-size: 16px;
    color: white;
    text-align: justify;
  }

  @media (max-width: 1608px) {
    padding: 0 15vw;
  }

  @media (max-width: 1378px) {
    padding: 0 10vw;
  }

  @media (max-width: 650px) {
    margin-bottom: 20px;
    padding: 0 5vw;

    h2 {
      font-size: 21px;
      letter-spacing: 2px;
    }

    p,
    li {
      text-align: left;
      font-size: 14px;
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 20vw;

  img {
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 5px -3px black;
  }

  @media (max-width: 1608px) {
    padding: 0 15vw;
  }

  @media (max-width: 1378px) {
    padding: 0 10vw;
  }

  @media (max-width: 650px) {
    padding: 0 5vw;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;
