import React, { Component } from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

class Footer extends Component {
  render() {
    return (
      <div>
        <SocialWrapper>
          <a
            href="https://www.facebook.com/RoadRedemptionGame/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="facebook" size="big" className="social-icon" />
          </a>
          <a
            href="https://youtu.be/3P06rdib6EU"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="youtube" size="big" className="social-icon" />
          </a>
          <a
            href="https://twitter.com/roadredemption"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="twitter" size="big" className="social-icon" />
          </a>
          <a
            href="https://www.instagram.com/tripwireinteractive/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="instagram" size="big" className="social-icon" />
          </a>
          <a
            href="https://www.twitch.tv/tripwireinteractive"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="twitch" size="big" className="social-icon" />
          </a>
        </SocialWrapper>
        <Copyright>
          <h4 style={{ margin: "15px 0" }}>
            © 2018 Tripwire Interactive - All Rights Reserved
          </h4>
        </Copyright>
      </div>
    );
  }
}

export default Footer;

const SocialWrapper = styled.div`
  background: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 10px;
  border-top: solid;
  border-width: 2px;
  border-color: #fde704;

  a {
    margin: 2vw;
    color: white;
  }

  a:hover {
    color: #fde704;
  }

  @media (max-width: 650px) {
    padding: 10px 0;
    justify-content: space-around;
  }
`;

const Copyright = styled.div`
  background: black;
  color: white;
  text-align: center;
  padding: 10px 0;
`;
