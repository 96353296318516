import React, { Component } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import Headroom from "react-headroom";

import arrow from "../library/images/icon-arrow-down.png";
import ESRB from "../library/images/RR_ESRB.gif";

class VideoLoop extends Component {
  render() {
    return (
      <div>
        <LoopWrapper>
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              muted
              playing
              loop
              url="https://cosmic-s3.imgix.net/f6e5fd20-dba3-11e8-8e48-c530e0cbc683-ezgif.com-crop%20(2).mp4"
              width="100%"
              height="100%"
            />
          </div>
        </LoopWrapper>
        <LogoWrapper>
          <a
            href="http://www.roadredemption.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://cosmic-s3.imgix.net/64d11670-dddf-11e8-95d7-dfced904ba0c-RR_Logo_Hires_TrasparenrBack%20(1).png"
              alt="Road Redemption"
            />
          </a>
        </LogoWrapper>
        <ESRBWrapper>
          <img src={ESRB} alt="ESRB: Rated Mature 17+" />
        </ESRBWrapper>
        <ReviewWrapper>
          <img
            src="https://cosmic-s3.imgix.net/2ba3e960-e698-11e8-9ace-09de3b85ec90-720x.png"
            alt="Nintendo Life"
          />
          <h1>- 8/10</h1>
          <p>
            "The wait’s over, Road Rash fans: this is the modern spiritual
            successor you've been waiting for all these years"
          </p>
        </ReviewWrapper>
        <Headroom>
          <ArrowWrapper>
            <img src={arrow} alt="Scroll Down" />
          </ArrowWrapper>
        </Headroom>
      </div>
    );
  }
}

export default VideoLoop;

const LoopWrapper = styled.div`
  margin-top: -45px;

  .player-wrapper {
    position: relative;
    padding-top: 46.9%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  margin-top: -18vh;
  left: 0;

  img {
    height: 150px;
  }
`;

const ESRBWrapper = styled.div`
  position: absolute;
  margin-top: -11vh;
  right: 10px;

  img {
    height: 80px;
  }
`;

const ReviewWrapper = styled.div`
  position: absolute;
  left: 23vw;
  top: 0;
  width: 50vw;
  text-align: center;
  font-style: Italic;
  color: white;

  img {
    height: 50%;
    width: 50%;
  }

  h1 {
    margin-top: -15px;
    font-size: 3vw;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
  }

  p {
    margin-top: -10px;
    font-size: 1.5vw;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  left: 46.5vw;
  margin-top: -6vw;

  img {
    height: 5vw;
  }
`;
