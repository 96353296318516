import React, { Component } from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "../slider-animations.css";
import "../styles.css";
import { sliderImages } from "../slider-images";
import styled from "styled-components";

class Carousel extends Component {
  render() {
    return (
      <CarouselWrapper>
        <Slider className="slider-wrapper" touchDisabled="true" autoplay={3000}>
          {sliderImages.map((item, index) => (
            <div
              key={index}
              className="slider-content"
              style={{
                background: `url('${item.image}') no-repeat center center`
              }}
            >
              <div className="inner">
                <h1>{item.description}</h1>
                <p>{item.title}</p>
              </div>
            </div>
          ))}
        </Slider>
      </CarouselWrapper>
    );
  }
}

export default Carousel;

const CarouselWrapper = styled.div`
  border-top: solid;
  border-bottom: solid;
  border-width: 2px;
  border-color: #fde704;
  font-family: "Titillium Web", sans-serif;
`;
