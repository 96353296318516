import React, { Component } from "react";
import styled from "styled-components";

import RRBanner from "../library/images/RR_Mobile_Banner.jpg";

class MobileBanner extends Component {
  render() {
    return (
      <BannerWrapper>
        <a
          href="http://www.roadredemption.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={RRBanner}
            alt="Road Redemption Banner"
            style={{ marginBottom: "-6px" }}
          />
        </a>
      </BannerWrapper>
    );
  }
}

export default MobileBanner;

const BannerWrapper = styled.div`
  img {
    width: 100vw;
  }
`;
