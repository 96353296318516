import React, { Component } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

class Trailer extends Component {
  render() {
    return (
      <VideoWrapper>
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/3P06rdib6EU"
            width="100%"
            height="100%"
            style={{ boxShadow: "0 5px 5px -3px black" }}
          />
        </div>
      </VideoWrapper>
    );
  }
}

export default Trailer;

const VideoWrapper = styled.div`
padding: 0 20vw;

@media (max-width: 1608px) {
  padding: 0 15vw;
}

@media (max-width: 1378px) {
  padding: 0 10vw;
}

@media (max-width: 650px) {
  padding: 0 5vw;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
 
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}`;
