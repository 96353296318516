import React, { Component } from "react";
import styled from "styled-components";
import Media from "react-media";
import { Image, Popup } from "semantic-ui-react";

const priceHoverStyle = {
  opacity: 0.95,
  padding: "2em",
  fontFamily: '"Titillium Web", sans-serif'
};

class BuyNowLinks extends Component {
  render() {
    return (
      <Media query="(max-width: 1024px)">
        {matches =>
          matches ? (
            <LinksWrapper>
              <a
                href="https://store.playstation.com/en-us/product/UP0115-CUSA13038_00-ROADREDEMPTION00"
                target="_blank"
                rel="noopener noreferrer"
                className="buy-link"
              >
                <img
                  src="https://cosmic-s3.imgix.net/0a8ff830-e1f3-11e8-9e3a-f3c8a196b06e-PSUS.png"
                  alt="Playstation Store"
                />
              </a>
              <a
                href="https://store.playstation.com/en-gb/product/EP0114-CUSA13020_00-ROADREDEMPTION00"
                target="_blank"
                rel="noopener noreferrer"
                className="buy-link"
              >
                <img
                  src="https://cosmic-s3.imgix.net/7c8c7a90-dba8-11e8-960b-dd870054d9db-PSEU.png"
                  alt="Playstation Store Europe"
                />
              </a>
              <a
                href="https://www.nintendo.com/games/detail/road-redemption-switch"
                target="_blank"
                rel="noopener noreferrer"
                className="buy-link"
              >
                <img
                  src="https://cosmic-s3.imgix.net/28a658a0-e1e9-11e8-a78a-b55cf4487289-SwitchUS-(1).png"
                  alt="Nintendo Switch Store"
                />
              </a>
              <a
                href="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Road-Redemption-1466778.html"
                target="_blank"
                rel="noopener noreferrer"
                className="buy-link"
              >
                <img
                  src="https://cosmic-s3.imgix.net/95210d00-dba8-11e8-b537-b7d9f9fad6ff-SwitchEU.png"
                  alt="Nintendo Switch Store"
                />
              </a>
              <a
                href="https://www.microsoft.com/en-us/p/road-redemption/bv5glfhzthqh"
                target="_blank"
                rel="noopener noreferrer"
                className="buy-link"
              >
                <img
                  src="https://cosmic-s3.imgix.net/91505130-ffdf-11e8-8b6b-7d3b941d371e-XboxLogo.png"
                  alt="Xbox Store"
                />
              </a>
              <a
                href="https://store.steampowered.com/app/300380/Road_Redemption/"
                target="_blank"
                rel="noopener noreferrer"
                className="buy-link"
              >
                <img
                  src="https://cosmic-s3.imgix.net/8def0280-dba8-11e8-b537-b7d9f9fad6ff-SteamLogo.png"
                  alt="Steam Store"
                />
              </a>
            </LinksWrapper>
          ) : (
            <LinksWrapper>
              <a
                href="https://store.playstation.com/en-us/product/UP0115-CUSA13038_00-ROADREDEMPTION00"
                target="_blank"
                rel="noopener noreferrer"
                className="buy-link"
              >
                <img
                  src="https://cosmic-s3.imgix.net/0a8ff830-e1f3-11e8-9e3a-f3c8a196b06e-PSUS.png"
                  alt="Playstation Store"
                />
              </a>
              <a
                href="https://store.playstation.com/en-gb/product/EP0114-CUSA13020_00-ROADREDEMPTION00"
                target="_blank"
                rel="noopener noreferrer"
                className="buy-link"
              >
                <img
                  src="https://cosmic-s3.imgix.net/7c8c7a90-dba8-11e8-960b-dd870054d9db-PSEU.png"
                  alt="Playstation Store Europe"
                />
              </a>
              <a
                href="https://www.nintendo.com/games/detail/road-redemption-switch"
                target="_blank"
                rel="noopener noreferrer"
                className="buy-link"
              >
                <img
                  src="https://cosmic-s3.imgix.net/28a658a0-e1e9-11e8-a78a-b55cf4487289-SwitchUS-(1).png"
                  alt="Nintendo Switch Store"
                />
              </a>
              <a
                href="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Road-Redemption-1466778.html"
                target="_blank"
                rel="noopener noreferrer"
                className="buy-link"
              >
                <img
                  src="https://cosmic-s3.imgix.net/95210d00-dba8-11e8-b537-b7d9f9fad6ff-SwitchEU.png"
                  alt="Nintendo Switch Store"
                />
              </a>
              <a
                href="https://www.microsoft.com/en-us/p/road-redemption/bv5glfhzthqh"
                target="_blank"
                rel="noopener noreferrer"
                className="buy-link"
              >
                <img
                  src="https://cosmic-s3.imgix.net/91505130-ffdf-11e8-8b6b-7d3b941d371e-XboxLogo.png"
                  alt="Xbox Store"
                />
              </a>
              <a
                href="https://store.steampowered.com/app/300380/Road_Redemption/"
                target="_blank"
                rel="noopener noreferrer"
                className="buy-link"
              >
                <Popup
                  trigger={
                    <Image
                      src="https://cosmic-s3.imgix.net/8def0280-dba8-11e8-b537-b7d9f9fad6ff-SteamLogo.png"
                      alt="Steam Store"
                    />
                  }
                  inverted
                  header={`Current price: ${this.props.currentSteamPrice}`}
                  content={`Discount: ${this.props.currentSteamDiscount}%`}
                  on="hover"
                  position="top left"
                  style={priceHoverStyle}
                />
              </a>
            </LinksWrapper>
          )
        }
      </Media>
    );
  }
}

export default BuyNowLinks;

const LinksWrapper = styled.div`
  margin-top: -1px;
  background: black;
  padding: 35px 0;
  display: flex;
  justify-content: space-around;

  .buy-link {
    display: flex;
    justify-content: center;
  }

  img {
    background: black;
    width: 70%;
    height: 100%;
    padding: 0.5vw;
    border: solid;
    border-radius: 8px;
    border-width: 1px;
    border-color: rgb(135, 135, 135);
  }

  img:hover {
    border-color: #fde704;
  }

  @media (max-width: 650px) {
    padding: 20px 5vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 60%;
      height: 100%;
      padding: 1vw;
    }

    .buy-link {
      margin: 5px 0;
    }
  }
`;
